<template>
  <div class="re-password-container">
    <div class="box verification" v-if="step === 1">
      <div class="t"><span>找回密码</span></div>
      <el-input class="inp" v-model="account" placeholder="输入手机号/邮箱" />
      <div class="get-code">
        <el-input class="inp" v-model="code" placeholder="请输入验证码" />
        <span v-if="!time" :class="{ canPress: getCodeBtn }" @click="getCode"
          >获取验证码</span
        >
        <span v-else>{{ time }}s 后重新发送</span>
      </div>
      <div class="next" :class="{ canPress: nextBtn }" @click="next">
        下一步
      </div>
    </div>
    <div class="box re-set" v-if="step === 2">
      <div class="t"><span>重置密码</span></div>
      <el-input
        class="inp"
        v-model.trim="password"
        placeholder="新密码，密码6-16位，需组合数字、字母和符号"
        show-password
      />
      <el-input
        class="inp"
        show-password
        v-model.trim="repassword"
        placeholder="再输入一次"
      />
      <div class="submit" :class="{ canPress: submitBtn }" @click="submit">
        确认
      </div>
    </div>
  </div>
</template>
<script>
import {
  isMobile,
  isEmail,
  validatenull,
  validatePassword,
} from "@/util/validate";
export default {
  components: {},
  data() {
    return {
      account: "",
      code: "",
      password: "",
      repassword: "",
      time: 0,
      step: 1,
    };
  },
  watch: {
    time(v) {
      v &&
        setTimeout(() => {
          this.time--;
        }, 1000);
    },
  },
  methods: {
    getCode() {
      if (isMobile(this.account)) {
        this.$api
          .sendPhoneCodeForget({ phone: this.account })
          .then((resolve) => {
            if (resolve.code === 200) {
              this.$message.success("发送成功");
              this.time = 60;
            }
          });
      } else if (isEmail(this.account)) {
        this.$api
          .sendEmailCodeForget({ email: this.account })
          .then((resolve) => {
            if (resolve.code === 200) {
              this.$message.success("发送成功");
              this.time = 60;
            }
          });
      } else {
        this.$message.error("请输入正确的账号");
      }
    },
    next() {
      if (!isMobile(this.account) && !isEmail(this.account)) {
        this.$message.error("请输入正确的账号");
        return;
      }
      if (validatenull(this.code)) {
        this.$message.error("请输入验证码");
        return;
      }
      this.$api
        .checkCode({
          account: this.account,
          code: this.code,
        })
        .then((resolve) => {
          if (resolve.code === 200) {
            this.step = 2;
          } else if (resolve.code === 400) {
            this.$message.error("验证码错误");
          }
        });
    },
    submit() {
      let { repassword, password } = this;
      //password.length < 6 || password.length > 16
      if (!validatePassword(password)) {
        this.$message.error("密码为6-16位，需组合数字、字母和符号");
        return;
      }
      if (repassword === password) {
        this.$api
          .sendRePassword({
            account: this.account,
            password: this.password,
            code: this.code,
          })
          .then((resolve) => {
            if (resolve.code === 200) {
              this.$message.success("重置密码成功");
              this.$router.push("/");
            }
          });
      } else {
        this.$message.error("密码不一致");
      }
    },
  },
  computed: {
    getCodeBtn() {
      return isMobile(this.account) || isEmail(this.account);
    },
    nextBtn() {
      return this.getCodeBtn && this.code.length > 0;
    },
    submitBtn() {
      return this.password.length > 0 && this.repassword.length > 0;
    },
  },
};
</script>
<style scoped lang="scss">
.re-password-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url("../assets/bg1.png");
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  input {
    width: 100%;
    height: 51px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0.45);
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.45);
    text-indent: 19px;
    box-sizing: border-box;
    margin-top: 30px;
    &:focus {
      outline: 0;
    }
  }
  .inp::v-deep {
    margin-top: 30px;
    border: 1px solid rgba(255, 255, 255, 0.45);
    border-radius: 2px;
    background: rgba(255, 255, 255, 0) !important;
    box-sizing: border-box;
    height: 51px;
    width: 100%;
    .el-input__inner {
      height: 100%;
      font-size: 16px;
      //font-family: PingFangSC-Regular, PingFang SC;
      background: rgba(255, 255, 255, 0) !important;
      //font-weight: 400;
      color: rgba(255, 255, 255, 0.45) !important;
      //text-indent: 19px;
      border: 0;
      &:focus {
        outline: 0;
      }
    }
  }
}
.box {
  width: 485px;
  height: 373px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  //opacity: 0.2;
  margin-right: 200px;
  box-sizing: border-box;
  padding: 0 45px;
  .t {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    border-bottom: 1px solid rgba(238, 238, 238, 0.44);
    > span {
      font-weight: 600;
      color: #ffffff;
      height: 60px;
      display: inline-block;
      line-height: 60px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 44px;
        height: 2px;
        background: #fff;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.next,
.submit {
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  height: 51px;
  line-height: 51px;
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
  cursor: pointer;
  margin-top: 40px;
}
.get-code {
  display: flex;
  align-items: flex-end;
  > span {
    flex-shrink: 0;
    display: inline-block;
    width: 128px;
    height: 51px;
    line-height: 51px;
    text-align: center;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    margin-left: 8px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }
}
.canPress {
  background: #c20c0c !important;
  color: #ffffff !important;
}
</style>
